import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import StatsModal from './StatsModal';

function TournamentStats ({ statistics, fontColor, setStatDetails }) {
  
  const { t } = useTranslation();

  const [eachRegions, setEachRegions] = useState([])

  useEffect(() => {
    populateRegionItems()
  }, [])
  
  let regions = 
    statistics &&
    statistics.length > 0 &&
    statistics.filter((stat, i, self) => {
      return i == self.findIndex((t) => {
        return t.region == stat.region
      })
    })
  regions = regions && regions.length > 0 && regions.map((item) => item.region)
  
  const populateRegionItems = () => {
    let regionItems = {}
    if (
      regions &&
      statistics &&
      regions.length > 0 &&
      statistics.length > 0
    ) {
      regions.forEach((region) => {
        regionItems[region] = []
        statistics.forEach((stat) => {
          if (region == stat.region) {
            regionItems[region].push(stat)
          }
        })
      })
    }
    let eachRegionsArr = []
    for (let key in regionItems) {
      eachRegionsArr.push({
        [key]: regionItems[key]
      })
    }
    setEachRegions(eachRegionsArr)
  }

  const renderRegionComponents = (eachRegions, region) => {
    let components = []
    eachRegions.forEach((eachRegion) => {
      const regionItems = 
        eachRegion &&
        eachRegion[region]
      regionItems && regionItems.forEach((regItem, i) => {
        const coverImage = regItem && regItem.cover_img_url
        const detailsImage = regItem && regItem.details_img_url
        console.log('coverImage >>>', coverImage)
        components.push(
          <div key={i} className="w-full h-full cursor-pointer" onClick={() => setStatDetails(detailsImage)}>
            <img className="object-contain w-full h-full" src={coverImage} alt="" />
          </div>
        )
      })
    })
    return components
  }

  return (
    <div>
      {
        regions && regions.length > 0 && regions.map((region) => {
          return (
            <section>
              <div className={`max-w-7xl mx-auto sm:mt-10 mt-6 px-4 py-6 sm:px-12 sm:py-8 space-y-8 text-${fontColor ? fontColor : "black"}`}>
                <p className='text-center text-xl sm:text-3xl font-bold'>
                  {`STATISTIK SEMENTARA (${region.toUpperCase()})`}
                </p>
              </div>
              {
                eachRegions && eachRegions.length > 0 && (
                  <div className='grid grid-cols-2 sm:grid-cols-4 flex gap-4 sm:gap-6 px-10 py-2'>
                    {renderRegionComponents(eachRegions, region)}
                  </div>
                )
              }
            </section>
          )
        })
      }
    </div>
  )
}

export default TournamentStats