import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { useTranslation } from 'react-i18next';

// Components
import TournamentFAQ from 'components/tournament_details/TournamentFAQ';
import TournamentTeamList from 'components/tournament_details/TournamentTeamList';
import TournamentTimeline from 'components/tournament_details/TournamentTimeline';
import TournamentRegistrationForm from 'components/tournament_details/TournamentRegistrationForm';
import TournamentTerms from 'components/tournament_details/TournamentTerms';

// Apis
import useFetch from 'utils/hooks/useFetch'
import { GET_TOURNAMENT, GET_TOURNAMENT_SCHEDULE, GET_TOURNAMENT_FAQS } from 'api';
import TournamentStats from 'components/tournament_details/TournamentStats';
import StatsModal from 'components/tournament_details/StatsModal';

function TournamentDetails() {
  const { data: tournament, fetch: getTournament } = useFetch(GET_TOURNAMENT)
  const { data: schedules, fetch: getTournamentSchedules } = useFetch(GET_TOURNAMENT_SCHEDULE)
  const { data: tournamentFaqs, fetch: getTournamentFaqs } = useFetch(GET_TOURNAMENT_FAQS)

  const { t } = useTranslation();
  const { id } = useParams();

  const [isRegistrationFormOpen, setIsRegistrationFormOpen] = useState(false);
  const [statDetails, setStatDetails] = useState('')

  useEffect(() => {
    getTournament({
      params: {
        id
      }
    })
  }, [
    getTournament,
    id
  ])

  useEffect(() => {
    if (tournament && tournament.id) {
      getTournamentSchedules({
        params: {
          tournament_id: tournament.id
        },
        data: {
          sort: ['id', 'ASC'],
        }
      })
    }
  }, [
    tournament,
    getTournamentSchedules
  ])

  useEffect(() => {
    if (tournament && tournament.id) {
      getTournamentFaqs({
        params: {
          tournament_id: tournament.id
        }
      })
    }
  }, [
    tournament,
    getTournamentFaqs
  ])

  const handleButtonRegister = (link) => {
    if (link && link !== '') {
      window.open(link, '_blank')
    } else {
      setIsRegistrationFormOpen(!isRegistrationFormOpen)
    }
  }

  return (
    <>
      <div className='fixed w-full h-full'>
        <img className='object-cover object-top h-full w-full' src={tournament && tournament.bg_image_url} />
      </div>
      <div className='pb-8'>

        <section>
          <div className='relative z-20'>
            <img src={tournament && tournament.header_image_url} alt="main" className='w-full object-contain object-center' />
          </div>
        </section>

        {
          tournament && tournament.hero_left_image_url && (
            <img className='top-24 left-0 opacity-20 fixed w-48 sm:w-96' src={tournament && tournament.hero_left_image_url} />
          )
        }
        {
          tournament && tournament.hero_right_image_url && (
            <img className='bottom-0 right-0 opacity-20 fixed w-48 sm:w-96' src={tournament && tournament.hero_right_image_url} />
          )
        }
        {
          tournament && tournament.divider_img_url && (
            <div className='relative z-20'>
              <img alt="divider" className='w-full md:w-4/5 my-8 mx-auto object-contain object-center' src={tournament && tournament.divider_img_url} />
            </div>
          )
        }

        <section className='app-animation-scale-start'>
          <div className='max-w-7xl mx-auto px-4 py-6 md:px-12 md:py-8 space-y-8'>
            {tournament && tournament.is_showing_page_title !== false && (
              <p className="text-center text-xl sm:text-3xl font-bold">
                About {tournament && tournament.title}
              </p>
            )}
            <div className={`text-center text-sm sm:text-base mx-auto text-${tournament && tournament.font_color ? tournament.font_color : "black"}`} dangerouslySetInnerHTML={{ __html: tournament && tournament.description }} />
          </div>
        </section>

        {
          tournament && tournament.divider_img_url && (
            <div className='relative z-20'>
              <img alt="divider" className='w-full md:w-4/5 my-8 mx-auto object-contain object-center' src={tournament && tournament.divider_img_url} />
            </div>
          )
        }

        <section className='app-animation-scale-start'>
          {
            tournament && tournament.contents && tournament.contents.map((content) => {
              if (content && content.is_description_image && content.description_image_url) {
                return (
                  <div className={`max-w-7xl mx-auto px-4 py-6 sm:px-12 sm:py-8 space-y-8 text-${tournament && tournament.font_color ? tournament.font_color : "black"}`}>
                    {
                      content.with_title && (
                        <p className='text-center text-xl sm:text-3xl font-bold mb-12'>
                          {content && content.title}
                        </p>
                      )
                    }
                    <a target="_blank" rel="noopener noreferrer" href={content && content.description_image_link || '#'}>
                      <img src={content.description_image_url} alt="main" className='w-full object-contain object-center md:px-24' />
                    </a>
                  </div>
                )
              }
              if (content && !content.is_description_image) {
                return (
                  <div className={`max-w-7xl mx-auto px-4 py-6 md:px-12 md:py-8 space-y-8 text-${tournament && tournament.font_color ? tournament.font_color : "black"}`}>
                    {
                      content.with_title && (
                        <p className='text-center text-xl sm:text-3xl font-bold'>
                          {content && content.title}
                        </p>
                      )
                    }
                    <p className='text-center text-sm sm:text-base mx-auto' dangerouslySetInnerHTML={{ __html: content && content.description }} />
                  </div>
                )
              }
            })
          }
        </section>

        {/* Team highlight section */}
        <section className='app-animation-scale-start'>
          {
            tournament && tournament.id && tournament.id == 20 && (
              <div className='grid grid-cols-1 sm:grid-cols-2 px-10 sm:pt-10 sm:pb-5 justify-items-center gap-4 sm:gap-6'>
                <img className='w-[500px] sm:w-[600px] h-full object-contain my-4 sm:my-0' src='https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/087eadc8c18109e4c1dfb8756ef840569632b26fa2fcb2aa674e9c7311e7b146f2a702f38013b97df9c01ef9ed5ed95c277d2d855d5644cf86e0f6e43193d7e53da823757427e96a7104917d3187eacf870b0cfc6cb87b1e8a25d69b4ac64de6bc7b824df291e89f74dafb4b1a885bd00792e2da4980675e0a39/file-1734453762829.png' />
                <img className='w-[500px] sm:w-[600px] h-full object-contain my-4 sm:my-0' src='https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/bcad5475ddc1897770019e98465f8b072f41c74f3b4512b7449aff5a8f2e1e5b9b3121e8cb341ed742bc3467b85a9eeee6febf566fc309168464b0bf4faf4003ae701f9df8034efbc1872722161f3a44232a960d63d042f85517edf3c6ba7c64c9dfd3309dba6900d061bece521704b7e4d35f28efc4e2697d5c/file-1734453783248.png' />
              </div>
            )
          }
        </section>

        <section className='app-animation-scale-start'>
          {
            tournament && tournament.with_statistic && (
              <TournamentStats 
                statistics={tournament && tournament.statistics} 
                fontColor={tournament && tournament.font_color} 
                setStatDetails={setStatDetails} />
            )
          }
        </section>

        <section className='app-animation-translate-start'>
          {
            tournament && tournament.is_wave && schedules && schedules.length > 0 && (
              <TournamentTimeline selectedTournamentTimelines={schedules} />
            )
          }
          {
            tournament && !tournament.is_wave && schedules && schedules.length > 0 && (
              <div className={`max-w-7xl mx-auto px-4 py-6 md:px-12 md:py-8 space-y-8 text-${tournament && tournament.font_color ? tournament.font_color : "black"}`}>
                <p className='text-center text-xl sm:text-3xl font-bold'>
                  {schedules[0] && schedules[0].title}
                </p>
                <p className='text-center text-sm sm:text-base mx-auto' dangerouslySetInnerHTML={{ __html: schedules[0] && schedules[0].description_html }} />
              </div>
            )
          }
        </section>

        <section className='app-animation-scale-start'>
          <div className='app-main-container mx-auto space-y-8'>
            <div className='text-center'>
              {isRegistrationFormOpen ? (
                <TournamentRegistrationForm id={id} setIsRegistrationFormOpen={setIsRegistrationFormOpen} schedules={schedules} tournament={tournament} />
              ) : (
                <button onClick={() => handleButtonRegister(tournament && tournament.registration_link)} className={`uppercase text-sm sm:text-base bg-${tournament && tournament.register_bg_color ? tournament.register_bg_color : 'red'}-500 hover:bg-${tournament && tournament.register_bg_color ? tournament.register_bg_color : 'red'}-600 text-${tournament && tournament.register_text_color ? tournament.register_text_color : "white"} font-bold py-4 px-8 rounded-full`} >
                  {t('registerNow')}
                </button>
              )}
            </div>
            <div className='text-center'>
              <a href={tournament && tournament.rule_book_url || '#'} target="_blank" rel="noopener noreferrer" className={`bg-transparent uppercase text-sm sm:text-base text-${tournament && tournament.font_color ? tournament.font_color : "black"} hover:bg-gray-300 font-semibold py-2 px-8 border border-${tournament && tournament.font_color ? tournament.font_color : "black"} border-2 rounded-full cursor-pointer`}>
                {t('rulesBook')}
              </a>
            </div>
          </div>
        </section >

        <section className='app-animation-translate-start'>
          {
            tournament && tournament.terms && tournament.terms.length > 0 && (
              <TournamentTerms
                terms={tournament && tournament.terms}
                bulletIcon={tournament && tournament.bullet_icon_url}
                fontColor={tournament && tournament.font_color}
              />
            )
          }
        </section>

        <section className='app-animation-translate-start'>
          {tournament && tournament.is_showing_registered_team !== false && (
            <TournamentTeamList tournament={tournament} schedules={schedules} />
          )}
        </section>


        <section className='app-animation-translate-start'>
          {
            tournamentFaqs && tournamentFaqs.length > 0 && (
              <TournamentFAQ tournamentFaqs={tournamentFaqs} fontColor={tournament && tournament.font_color} />
            )
          }
        </section>

        <section className='app-animation-translate-start'>
          <div className='max-w-7xl mx-auto px-4 py-6 md:px-12 md:py-8 space-y-8'>
            {tournament && tournament.footer_html && (
              <div className={`text-center text-sm sm:text-base mx-auto text-${tournament && tournament.font_color ? tournament.font_color : "black"}`} dangerouslySetInnerHTML={{ __html: tournament && tournament.footer_html }} />
            )}
          </div>
        </section>

        <StatsModal details={statDetails} setStatDetails={setStatDetails} />

      </div >
    </>
  )
}

export default TournamentDetails