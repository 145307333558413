import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

function StatsModal ({ details, setStatDetails }) {

  const { t } = useTranslation();

if (details) {
    return (
      <>
        <div className='fixed top-0 bottom-0 right-0 left-0 z-50 flex justify-center items-center'>
          <img src={details} className='w-full h-[55%] sm:h-[80%] object-contain' />
          <div className='bg-white absolute right-10 top-10 rounded-full px-4 py-2 text-xl cursor-pointer' onClick={() => setStatDetails('')}>
            x
          </div>
        </div>
        <div className='fixed top-0 bottom-0 right-0 left-0 z-40 bg-app-blue/80' />
      </>
    )
  } else {
    return null
  }
}

export default StatsModal